import React, { useState, useEffect } from 'react';
import { Header, Title } from 'components/primaries';
import { DataListPage } from "pages/DataListPage"
import { Panel } from 'components/primaries'
import { Modal, Select, Row, Col, Input, Button, notification } from 'antd';
import { ZenSmartAPI } from 'utils'
import { productComponentWeight, getComponentTypes, componentTypesRoute } from 'utils/apiRoutes';
import {alphabeticalData} from 'utils/sortData';
import { errorNoticationHandler } from 'utils/errorMessageHandler';
import styled, { createGlobalStyle, css } from 'styled-components'
import { ReactComponent as CloseIcon } from "static/icons/close.svg";
const Card = styled.div`
  border: 1px solid #b9b4b4;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 3px;
  min-height: 100px;
`
const TableStyle = createGlobalStyle`
.card {
    border: 2px solid #cfd9f9;
    border-radius: 5px;
    position: relative;
    font-family: sans-serif;
}
`
const CloseIconContainer = styled.div`
position: absolute;
// adjust to your liking
top: -4px;
right: -6px;
width: 16px;
height: 16px;
border-radius: 50%;
cursor: pointer;
${(props) =>
        props.showIcon &&
        css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
const ProductComponentWeightsPage = () => {
    const { Option } = Select;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [componentWeightData, setComponentWeightData] = useState({})
    const [componentTypes, setComponentTypes] = useState([])
    const manageComponentWeights = (row, refreshData) => {
        ZenSmartAPI.get(productComponentWeight(row.id))
            .then((res) => {
                setIsModalVisible(row)
                setComponentWeightData(res?.data?.data)
            })
            .catch((res) => {
            })
    }
    const getComponents = () => {
        ZenSmartAPI.get(getComponentTypes)
            .then((res) => {
                setComponentTypes(res?.data?.data)
            })
            .catch((res) => {
            })
    }
    const handleSave = () => {
        Modal.confirm({
            content: `Are you sure you want to save changes?`,
            onOk: () => {
                ZenSmartAPI.put(productComponentWeight(isModalVisible.id), { data: componentWeightData })
                    .then((res) => {
                        handleCancel()
                    })
                    .catch((err) => {
                        errorNoticationHandler(err, "Unable to update component weights!")
                    })
            },
        });
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        setComponentWeightData({})
    };
    const addNewComponentType = (e) => {
        if (e.key === 'Enter') {
            if (!componentTypes.find(item => item.name.toLowerCase() === e.target.value.toLowerCase())) {
                const payload = {
                    name: e.target.value.split(' ') 
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')
                }
                Modal.confirm({
                    content: `Are you sure you want to add this new component type ${e.target.value}?`,
                    onOk: () => {
                        ZenSmartAPI.post(componentTypesRoute, payload)
                            .then((res) => {
                                getComponents()
                            }).catch((err) => {
                                errorNoticationHandler(err, "Unable to add new component type")
                            })
                    },
                });
            }
        }
    }
    const changeComponentType = (value, index) => {
        setComponentWeightData(prevData => {
            const keys = Object.keys(prevData);
            const indexPosition = keys.indexOf(index);
            const updatedData = {};
            keys.forEach((key, idx) => {
                if (idx === indexPosition) {
                    updatedData[value] = prevData[index];
                } else if (key !== index) {
                    updatedData[key] = prevData[key];
                }
            });
            return updatedData;
        });
    };
    const updateComponentWeight = (e, index, componentType, type = null) => {
        const { value } = e.target;
        if (!index && type == 'weightValue') {
            return
        }
        setComponentWeightData(prevData => {
            if (type == 'weightValue') {
                const regex = /^\d*\.?\d*$/;
                let updatedData = { ...prevData };
                if (regex.test(value)) {
                    updatedData[componentType][index].value = value;  // Use extracted value
                    updatedData[componentType] = Object.values(updatedData[componentType])
                }
                return updatedData;
            }
            else {
                let updatedData = { ...prevData[componentType] };
                const mainUpdateData = { ...prevData };
                updatedData[index].name = value
                updatedData = Object.values(updatedData)
                mainUpdateData[componentType] = updatedData;
                return mainUpdateData;
            }
        });
    };
    const deleteComponentWeight = (index, category) => {
        setComponentWeightData(prevData => {
            const updateData = { ...prevData }
            updateData[category] = updateData[category].filter((item, itemIndex) => index != itemIndex)
            return updateData
        });
    }
    const addNewWeight = (category) => {
        setComponentWeightData(prevData => {
            const updateData = { ...prevData }
            updateData[category].push({ name: '', value: '' });
            return updateData
        });
    }
    const addNewComponentWeight = (category) => {
        if (componentWeightData['']) {
            notification.error({
                message: 'Please input first a new component type before adding a new component weight.',
            });
            return
        }
        setComponentWeightData(prevData => {
            const updateData = { ...prevData }
            updateData[''] = []
            return updateData
        });
    }
    const deleteComponentType = (category) => {
        setComponentWeightData(prevData => {
            const updateData = { ...prevData }
            delete updateData[category];
            return updateData
        });
    }
    useEffect(() => {
        getComponents()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    console.log('componentWeightData :>> ', componentWeightData);
    return (
        <>
            <TableStyle />
            <Header>
                <Title>App Setup {">"} Product Component Weights</Title>
            </Header>
            <Panel title="Product Component Weights">
                <DataListPage model="products" select={['id', 'name', 'code']} link={"app/data-view/products/"} pageSize={50}
                    actionButtons={
                        [{
                            buttonLabel: "Manage Weights",
                            buttonType: "primary",
                            buttonAction: "normal",
                            actionFunction: manageComponentWeights,
                            reloadData: true
                        },
                        ]
                    }
                />
            </Panel>
            <Modal
                title={isModalVisible &&  `Product: ${isModalVisible?.name}`}
                visible={isModalVisible}
                onOk={handleSave}
                width={1000}
                onCancel={handleCancel}
                okText="Save" // Changing "OK" button text to "Save"
            >
                {isModalVisible &&
                    <>
                    {Object.entries(componentWeightData).map(([category, items]) => {
                        return (
                            <>
                                <Card className='card'>
                                    <h1 style={{ fontWeight: "bold", fontSize: 15 }}>Component Type:</h1>
                                    <Select
                                        optionFilterProp="children"
                                        showSearch
                                        style={{ width: "100%", padding: 5, marginTop: 5, marginBottom : 5 }}
                                        dropdownMatchSelectWidth={true}
                                        value={category}
                                        onInputKeyDown={(e) => addNewComponentType(e)}
                                        onChange={(e) => changeComponentType(e, category)}
                                    >
                                        {alphabeticalData(componentTypes, "name").map(item => {
                                            if (!Object.keys(componentWeightData).includes(item.name)) {
                                                return <Option value={item.name}>{item.name}</Option>
                                            }
                                        })}
                                        
                                    </Select>
                                    <h1 style={{ fontWeight: "bold", fontSize: 15, marginBottom: 5 }}>Weight Values:</h1>
                                    {Object.entries(items).map(([index, value]) => {
                                        return (
                                            <>
                                                <Row type='flex'>
                                                    <Col span={11} style={{ padding: 5 }}> <Input
                                                        placeholder="Enter this name field first before the weight value"
                                                        value={value.name}
                                                        onChange={(e) => updateComponentWeight(e, index, category)}
                                                    />
                                                    </Col>
                                                    <Col span={11} style={{ padding: 5 }}><Input
                                                        placeholder="Enter weight value"
                                                        value={value.value}
                                                        onChange={(e) => updateComponentWeight(e, index, category, "weightValue")}
                                                    />
                                                    </Col>
                                                    <Col span={2} style={{ padding: 5 }}>
                                                        <Button type="danger" onClick={() => deleteComponentWeight(index, category)}>Delete</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    })}
                                    <Row type="flex" justify="end">
                                        <Col style={{ padding: "5px 0px 5px 0px" }}>
                                            <Button type="primary" style={{ width: "100%" }} onClick={() => addNewWeight(category)}>Add New Weight Values</Button>
                                        </Col>
                                    </Row>
                                    <CloseIconContainer color="red"
                                        showIcon={true}>
                                        <CloseIcon width="20px" height="15px" onClick={() => deleteComponentType(category)} />
                                    </CloseIconContainer>
                                </Card>
                            </>
                        )
                    })}
                        <Row type="flex" justify="end">
                            <Col style={{ padding: "5px 0px 5px 0px" }}>
                                <Button type="primary" style={{ width: "100%" }} onClick={() => addNewComponentWeight()}>Add New Component Weight</Button>
                            </Col>
                        </Row>
                    </>
                }
            </Modal>
        </>
    )
}
export default ProductComponentWeightsPage