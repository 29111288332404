import React, {useState, useEffect} from 'react'
import styled, {createGlobalStyle} from 'styled-components'
import {Table, Row, Col, Tag, Button, Modal, notification, Popconfirm} from 'antd'
import {panelSortData} from 'utils/sortData'
import {PostbackDetailsModal} from './PostbackDetailsModal'
import {
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {postbackInitialData} from "utils/panelsInitialData"
import {resendPostbackRoute} from "utils/apiRoutes"
import {ZenSmartAPI} from 'utils'

const StyleTable = styled.div`
  font-family: 'Rubik', sans-serif;
`
const TableStyling = createGlobalStyle`
  .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    text-align: center;
    border: none !important;
    background-color: white;
  }

  .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
    text-align: center;
    border: 1px solid #f1f3ff;
  }
`
const ActionButton = styled(Button)`
  margin: 2%;
`
const TableData = (props) => {
    const {itemData, loading, setLoading, data, setData, ascend, setAscend, fetchData} = props
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPostback, setSelectedPostback] = useState(null);
    const sortingData = (data, property, ifNotProperty) => {
        if (ascend[property] === null) {
            setAscend({...postbackInitialData, [property]: true})
        } else {
            ascend[property] === true ? setAscend({
                ...postbackInitialData,
                [property]: false
            }) : setAscend({...postbackInitialData, [property]: true})
        }
        setData(panelSortData(data, property, ascend[property], ifNotProperty))
    }
    const retryPostback = id => {
        setLoading(true);
        ZenSmartAPI.post(resendPostbackRoute(id))
            .then((res) => {
                notification.success({message: "Resend Postback Initiated!"})
                setLoading(false)
            })
            .catch((res) => {
                if (res?.response?.data) {
                    // notification.error({message: res.response.data.message})
                    setLoading(false)
                }
            })
    }
    const displayPostbackDetails = (record) => {
        setSelectedPostback(record);
        setModalVisible(true);
    };
    const getHeaders = (property, text, ifNotProperty) => {
        return (
            <Row type="flex" align="middle" onClick={() => sortingData(data, property, ifNotProperty)}
                 style={{cursor: "pointer"}}>
                <Col span={ascend[property] === null ? 24 : 20}>
                    <p>{text}</p>
                </Col>
                {ascend[property] !== null &&
                    <Col span={2}>
                        <p>
                            {ascend[property] === false ?
                                <CaretDownOutlined style={{fontSize: 17, verticalAlign: "baseline", color: "grey"}}/>
                                : ascend[property] === true ? <CaretUpOutlined
                                    style={{fontSize: 17, verticalAlign: "baseline", color: "grey"}}/> : ""}
                        </p>
                    </Col>
                }
            </Row>
        )
    }
    const columns = [
        {
            title: getHeaders("postback_id", "POSTBACK ID", false),
            dataIndex: 'postback_id',
            key: 'postback_id',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("transaction_number", "TRANSACTION", true),
            dataIndex: 'transaction_number',
            key: 'transaction_number',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`}
                                    style={{color: "blue", textDecorationLine: "underline"}}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("order_number", "ORDER", true),
            dataIndex: 'order_number',
            key: 'order_number',
            render: (record, row, index) => {
                const value = <p><a href={`/${record.link}`}
                                    style={{color: "blue", textDecorationLine: "underline"}}>{record.value}</a></p>
                return rowStyle(record.value ? value : "-", index, row)
            }
        },
        {
            title: getHeaders("request", "REQUEST", false),
            dataIndex: 'request',
            key: 'request',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("postback_triggered", "POSTBACK TRIGGERED", false),
            dataIndex: 'postback_triggered',
            key: 'postback_triggered',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("endpoint", "ENDPOINT", false),
            dataIndex: 'endpoint',
            key: 'endpoint',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("status", "STATUS", false),
            dataIndex: 'status',
            key: 'status',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("no_of_retries", "NO OF RETRIES", false),
            dataIndex: 'no_of_retries',
            key: 'no_of_retries',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: getHeaders("retried_at", "LAST RETRIED AT", false),
            dataIndex: 'retried_at',
            key: 'retried_at',
            render(value, row, index) {
                return rowStyle(value ? value : "-", index, row)
            },
        },
        {
            title: "Actions",
            dataIndex: 'actions',
            key: 'actions',
            render: (obj, row, index) => {
                const actions = (
                    <>
                        <Popconfirm
                            placement="top"
                            title={'Are you sure you want to resend this postback?'}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => retryPostback(row.postback_id)}
                        >
                            <ActionButton type="primary">Resend</ActionButton>
                        </Popconfirm>
                        <ActionButton type="primary" onClick={e => displayPostbackDetails(row)}>View
                            Details</ActionButton>
                    </>
                );
                return rowStyle(actions, index);
            }
        },
    ];
    const rowStyle = (value, index) => {
        const odd = index % 2
        const obj = {
            props: {
                style: {background: "#f1f3ff", border: "none"},
            },
            children: <div>{value}</div>,
        };
        if (!odd) {
            obj.props.style = {border: "none"}
        }
        return obj
    }
    useEffect(() => {
        if (itemData) {
            const itemObj = itemData.map(data => {
                const obj = {
                    transaction_number: data.transaction_number,
                    order_number: data.order_number,
                    request: data.request,
                    postback_triggered: data.postback_triggered,
                    endpoint: data.endpoint,
                    status: data.status,
                    no_of_retries: data.no_of_retries,
                    retried_at: data.retried_at,
                    postback_id: data.postback_id,
                    callback_response: data.callback_response,
                    payload: data.payload
                }
                return obj;
            })
            setData(itemObj);
        }
    }, [itemData])
    return (
        <>
            <TableStyling/>
            <StyleTable>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    bordered
                    size="middle"
                    scroll={{y: "45vh"}}
                    pagination={{pageSize: 50}}
                />
            </StyleTable>
            <PostbackDetailsModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
                payload={selectedPostback ? selectedPostback.payload : null}
                callbackResponse={selectedPostback ? selectedPostback.callback_response : null}
            />
        </>
    )
}
export default TableData