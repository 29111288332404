export const wipInitialData = {
    vendor_name: null,
    transaction: null,
    order_number: null,
    line_id: null,
    block: null,
    box_number: null,
    dispatch_method_code: null,
    code: null,
    lamination_code: null,
    due_date: null,
    stage: null,
    stage_time: null,
    hrs_at_stage: null
}

export const holdInitialData = {
    order_number: null,
    transaction_id: null,
    vendor: null,
    line_id: null,
    primary_product_group: null,
    article_description: null,
    files_retrieved: null,
    on_hold_reason: null,
    due_date: null,
    age_on_hold: null
}
export const failInitialData = {
    fail_date_time: null,
    failed_by: null,
    failed_reason: null,
    line_id: null,
    product: null,
    transaction: null,
    last_scan_date_time: null,
    last_scan_type: null,
    last_scan_operator: null,
    lamination_code : null,
    due_date : null
}

export const dispatchInitialData = {
    transactions: null,
    address: null,
    line_id: null,
    provider: null,
    consignment_number: null
}
export const checkoutInitialData = {
    order_number: null,
    product: null,
    file: null,
    checked_out: null,
    box_number: null,
    reason: null,
    comments: null,
    due_date: null,
}
export const inOutInitialData = {
    hours: null,
    in_orders: null,
    in_units: null,
    out_orders: null,
    out_units: null,
}
export const failedTransactionInitialData = {
    client_number: null,
    brand: null,
    cobrand: null,
    failure_reason: null,
    created_date: null,
}

export const settingsInitialData = {
    setting: null,
    param: null,
    val: null,
    description: null
}
export const cellActivityInitialData = {
    operator: null,
    hrs_at_stage: null,
    avg_units_per_hr: null,
    units: null
}
export const operatorActivityInitialData = {
    scan_type: null,
    units: null,
    first_date_range: null,
    last_date_range: null,
    avg_unit_per_hr: null,
}
export const productFlowReporterInitialData = {
    vendor_name: null,
    transaction: null,
    order_number: null,
    line_id: null,
    block: null,
    box_number: null,
    dispatch_method_code: null,
    code: null,
    due_date: null,
    stage: null,
    stage_time: null,
    hrs_at_stage: null,
    net_production_days: null,
    block_type: null,
}

export const postbackInitialData = {
    transaction_number: null,
    order_number: null,
    request: null,
    postback_triggered: null,
    endpoint: null,
    status: null,
    no_of_retries: null,
    retried_at: null,
}

export const lnSqInitialData = {
    product: null,
    finish: null,
    size: null,
    sqFeetStep1: null,
    sqFeetStep2: null,
    lnFeetStep1: null,
    lnFeetStep2: null,
    timestamp: null
}
