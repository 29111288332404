import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input, Dropdown, Menu } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import { getDepartmentSummaryRoute, getAllDepartments, getDepartmentSummaryCSVRoute } from "utils/apiRoutes"
import { alphabeticalData } from "utils/sortData"
import moment from "moment";
import { DownOutlined } from '@ant-design/icons';
import axios from "axios";

const { RangePicker } = DatePicker;

const DropdownColStyle = styled(Col)`
  text-align: right; /* Aligns the dropdown to the right */
`;

const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;
`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`

const { Option } = Select;

const DepartmentSummaryPage = (props) => {
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [departments, setDepartments] = useState([])
    const [columnValues, setColumnValues] = useState([])
    const [headingSelectionValue, setHeadingSelectionValue] = useState("")
    const [dateRange, setDateRange] = useState([moment().subtract(1, 'days'), moment().subtract(1, 'days')]);

    const [payload, setPayload] = useState({
        department_id: null,
        from_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        to_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
    });

    const fetchDepartments = () => {
        setLoading(true)
        ZenSmartAPI.get(getAllDepartments)
            .then((res) => {
                setLoading(false)
                setDepartments(res.data.data)
            })
            .catch((res) => {
                setLoading(false)
            })
    }

    const handleDateChange = (dates) => {
        setDateRange(dates);
        const fromDate = dates[0] ? dates[0].format('YYYY-MM-DD') : ''
        const toDate = dates[1] ? dates[1].format('YYYY-MM-DD') : ''
        setPayload({ ...payload, from_date: fromDate, to_date:toDate })
    }

    const constructData = (data) => {
        const returnData = [];
        data.forEach((item) => {
            item.operator_data.forEach((operator) => {
                returnData.push({
                    scan_type: item.scan_type, // Add the scan_type to each operator's data
                    ...operator,              // Spread the operator data
                });
            });
        });
        return returnData;
    };

    const uniqueColumnValues = [...new Set(columnValues)];

    const fetchData = () => {
        console.log(payload);
        if (payload.department_id === null) {
            notification.error({ message: "Please select a department!" })
            return
        }
        setLoading(true)
        ZenSmartAPI.post(getDepartmentSummaryRoute, payload)
            .then((res) => {
                setLoading(false);
                const responseData = res.data.data;
                if (responseData.report_values.length > 0) {
                    const flattenedData = constructData(responseData.report_values);
                    setItemData(flattenedData); // Flattened data
                    setColumnValues([...new Set(responseData.columns)]); // Unique columns
                } else {
                    notification.error({ message: "No data found!" });
                    setItemData([]);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }
                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
        return true
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => handleDownloadCSV()}>
                Download CSV
            </Menu.Item>
        </Menu>
    );

    const handleDownloadCSV = () => {
        if (payload.department_id === null) {
            notification.error({ message: "Please select a department!" })
            return
        }

        ZenSmartAPI.get(getDepartmentSummaryCSVRoute(payload.department_id, payload.from_date, payload.to_date), {
            responseType: "blob", // Expect a binary response
        })
            .then((response) => {
                // Generate a dynamic filename with timestamp
                const timestamp = new Date().toISOString().replace(/[:\-T]/g, "").split(".")[0];
                const fileName = `department-summary-${timestamp}.csv`;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link); // Append to DOM to make click work in some browsers
                link.click();
                link.remove(); // Cleanup
            })
            .catch(() => {
                notification.error({
                    message: "Failed to download CSV",
                });
            });
    }

    const changeDepartment = (value) => {
        setPayload({ ...payload, department_id: value })
    }

    useEffect(() => {
        fetchDepartments()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} Operator Performance {">"} Department Summary</Title>
            </Header>
            <Panel title="DEPARTMENT SUMMARY">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={4} >
                            <Heading>DEPARTMENT</Heading>
                            <Select
                                optionFilterProp="children"
                                showSearch
                                style={{ marginTop: 10, width: '100%', textTransform: "capitalize" }} onChange={changeDepartment} placeholder="Select Department..." >
                                <Option value="">All</Option>
                                {alphabeticalData(departments, "name").map(data =>
                                    <Option value={data.id} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                        <DateColStyle span={8} >
                            <InputStyle />
                            <Heading>DATE RANGE:</Heading>
                            <RangePicker
                                style={{ marginTop: 10 }}
                                value={dateRange}
                                onChange={handleDateChange}
                            />
                        </DateColStyle>
                        <DateColStyle span={2}>
                            <br />
                            <StyleButton style={{ marginTop: 10 }} onClick={fetchData} loading={loading} type="primary">GO</StyleButton>
                        </DateColStyle>
                        <DropdownColStyle span={10}>
                            <Dropdown overlay={menu}>
                                <Button style={{ marginTop: 30 }}>
                                    Actions <DownOutlined />
                                </Button>
                            </Dropdown>
                        </DropdownColStyle>
                    </Row>
                    {headingSelectionValue !== "" &&
                        <DateColStyle span={6} style={{ padding: 30 }}>
                            <Heading style={{ fontWeight: "bold", textTransform: "capitalize" }}>{headingSelectionValue.toUpperCase()}</Heading>
                        </DateColStyle>
                    }
                </div>
                <TableData loading={loading} data={itemData} setData={setItemData} columnValues={columnValues} />
            </Panel>
        </>
    )
}

export default DepartmentSummaryPage