import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Panel } from 'components/primaries'
import { Row, Col, notification, Select, DatePicker, Button, Input } from 'antd'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import TableData from './TableData';
import _ from "lodash"
import { ZenSmartAPI } from 'utils'
import {getProductGroupsRoute, linearFeetSqFeetRoute} from "utils/apiRoutes"
import moment from 'moment';
import { lnSqInitialData } from "utils/panelsInitialData"
const Header = styled.header`
  margin-bottom: 24px;
`
const Title = styled.h1`
  font-size: 20px;
  color: ${color.heading};
  font-weight: normal;
`
const Heading = styled.h1`
  font-size : 15px;
  font-family: 'Rubik', sans-serif;
  font-weight : bold;
`
const DateColStyle = styled(Col)`
  padding : 10px;
`
const StyleButton = styled(Button)`
font-family: 'Rubik', sans-serif;

`
const InputStyle = createGlobalStyle`
.ant-input-lg::placeholder{
    color : blue;
  }
`
const { Option } = Select;

const LinearFtSqFt = (props) => {
    const [fetchDate, setFetchDate] = useState({  })
    const [itemData, setItemData] = useState([])
    const [loading, setLoading] = useState(false)
    const [originalData, setOriginalData] = useState([]);
    const [ascend, setAscend] = useState(lnSqInitialData)
    const [productGroups, setProductGroups] = useState([]);

    const fetchProductGroups = () => {
        setLoading(true)
        ZenSmartAPI.get(getProductGroupsRoute)
            .then((res) => {
                // Sort the data by 'name'
                const sortedData = res.data.data.sort((a, b) => {
                    // Ensure case-insensitive sorting
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                });
                setLoading(false)
                setProductGroups(sortedData)
            })
            .catch((res) => {
                setLoading(false)
            })
    }

    const fetchData = (primary_group = null) => {
        setLoading(true)
        ZenSmartAPI.get(linearFeetSqFeetRoute(primary_group))
            .then((res) => {
                setLoading(false)
                setItemData(res.data.data)
                setOriginalData(res.data.data)
                setAscend(lnSqInitialData)
            })
            .catch((error) => {
                setLoading(false);
                // handle validation errors
                if (_.get(error, 'response.status') === 422) {
                    notification.error({
                        message: "There are errors in search input",
                        description: _.chain(error.response.data.errors)
                            .values()
                            .flatten()
                            .first()
                            .value()
                    });
                }

                if (_.get(error, 'response.status') === 400) {
                    notification.error({
                        message: _.get(error, 'response.data.message',
                            'An unhandled error occurred. Please contact your supervisor.'),
                    });
                }

                if (_.get(error, 'response.status') > 499) {
                    notification.error({
                        message: 'An unhandled error occurred. Please contact your supervisor'
                    });
                }
            })
        return true
    }

    const searchOperation = async (value, source) => {
        if (value === '') {
            setItemData(originalData)
        } else {
            const result = await source.filter(item => {
                const itemValues = Object.values(item)
                const containsValue = itemValues.some(anItemValue => {
                    // if null ignore
                    if (anItemValue === null) {
                        return false
                    }

                    // handle objects
                    if (anItemValue.value) {
                        return anItemValue.value.toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
                    }

                    // handle dates
                    if (moment.unix(anItemValue).isValid()) {
                        return moment.unix(anItemValue).format('D MMM YYY, h:mma')
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase());
                    }

                    // plain values
                    return anItemValue.includes(value.toLowerCase())
                });
                return containsValue;
            })
            setItemData(result)
        }
    }

    const changeProductGroup = (value) => {
        fetchData(value);
    };

    useEffect(() => {
        fetchData()
        fetchProductGroups()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Header>
                <Title>Performance {">"} Linear Feet/ Sq Feet Panel</Title>
            </Header>
            <Panel title="Linear Feet/ Sq Feet Panel">
                <div style={{ padding: "10px 50px 50px 50px" }}>
                    <InputStyle />
                    <Row type="flex" align="middle">
                        <DateColStyle span={6} >
                            <Heading>PRODUCT GROUP</Heading>
                            <Select defaultValue={0} style={{ marginTop: 10, width: '100%' }} onChange={changeProductGroup} placeholder="Select Product Group" >
                                <Option value={0}>All Product Groups</Option>
                                {productGroups.map(data =>
                                    <Option value={data.name} style={{ textTransform: "capitalize" }}>{data.name.toLowerCase()}</Option>
                                )}
                            </Select>
                        </DateColStyle>
                    </Row>
                </div>

                <TableData loading={loading} data={itemData} setData={setItemData} ascend={ascend} setAscend={setAscend} />
            </Panel>
        </>
    )
}

export default LinearFtSqFt